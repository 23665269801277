import React from "react"
import { Link } from "gatsby"

export default function BottomHeroComponent(props) {
  return (
    <>
      <div
        className="bg-cover"
        style={{
          backgroundColor: props.backgroundColor,
          backgroundImage: `URL(${props.backgroundImage})`,
        }}
      >
        <div className="container max-w-screen-xl w-full text-white items-center pt-10 lg:pt-14">
          <div className={`bg-white w-full flex flex-col gap-y-4 md:flex-row rounded-t-[15px] px-10 py-[1.875rem] mt-[15px] text-left ${props.shadowColor}`}>
            <div className="w-full md:w-2/3">
              <span className="pb-2">
                <Link
                  to="/auctions"
                  className="text-[1.5rem] font-semibold text-medium-gray"
                >
                  {props.pageTitle}
                </Link>
              </span>
              <h1 className="text-balance my-0 text-charcoal">
                {props.title}
              </h1>
            </div>
            <div className="w-full md:w-1/3 px-0 md:px-5">
              <img
                alt={props.title}
                className="no-border h-60"
                src={props.summaryImageUrl}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
