import React, { useEffect, useRef, useState } from "react"
import { graphql, Link } from "gatsby"
import moment from "moment"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { AddToCalendarButton } from "add-to-calendar-button-react"
import FAQ from "../components/faq-components/faq"
import { auctionsFaqItems } from "../utils/data/faq"
import ContactForm from "../components/bodycomponents/contact-form"

import AuctionBottomHeroComponent from "../components/herocomponents/auctionbottomherocomponent"
import RED_BG from "../images/background-images/bg-red.svg"
import M_GRAY_BG from "../images/background-images/bg-medium-gray.svg"
import { colors } from "../utils/colors"
import LocationIcon from "../images/icons/SSLLC_Icons_Location_Gray-1.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleDown } from "@fortawesome/free-solid-svg-icons"

const AuctionDetail = ({ data, location }) => {
  const tabs = useRef(null)
  const [activeTab, setActiveTab] = useState(0)
  const [hoveredTab, setHoveredTab] = useState(null) // Track which tab is being hovered

  const auctionData = data.auction

  useEffect(() => {
    if (auctionData.description) {
      setActiveTab(0)
    }
  }, [auctionData.description])

  var startDate = new Date(auctionData.startDate.seconds * 1000)
  var endDate = new Date(auctionData.endDate.seconds * 1000)
  let startDateMoment = moment(startDate)
  let endDateMoment = moment(endDate)

  let isFuture = false
  let endDateNoHours = endDate.setHours(0, 0, 0, 0)
  if (endDateNoHours >= new Date().setHours(0, 0, 0, 0)) isFuture = true

  // var options = { timeStyle: "long", dateStyle: "long" }

  useEffect(() => {
    // eslint-disable-next-line no-undef
    $(tabs.current).easyResponsiveTabs({
      type: "horizontal",
      closed: false,
      tabidentify: "js-tabs-a", // The tab groups identifier
    })
  })

  return (
    <Layout>
      <SEO
        title={auctionData.title}
        description={auctionData.blurb}
        imageUrl={auctionData.detailImageUrl}
        url={location.href}
        canonicalUrl={location.origin + location.pathname}
      />

      <AuctionBottomHeroComponent
        backgroundImage={RED_BG}
        backgroundColor={colors.scarletRed}
        fontShadowColor={colors.red}
        shadowColor="shadow-red-top"
        pageTitle="Surplus Solutions Auctions"
        title={auctionData.title}
        summaryImageUrl={auctionData.summaryImageUrl}
        body={
          <>
            <img
              alt={auctionData.title}
              className="no-border h-60"
              src={auctionData.summaryImageUrl}
            />
          </>
        }
      />

      <div className="container max-w-screen-xl py-14 shadow-custom-out">
        <div className="pb-6">
          <img
            alt={auctionData.title}
            className="no-border h-52 w-full object-contain"
            src={auctionData.detailImageUrl}
          />
        </div>
        <div className="flex flex-col md:flex-row justify-between gap-x-0 md:gap-x-6">
          <div className="w-full md:w-1/4 flex flex-col gap-y-6 text-center">
            <p className="text-medium-gray text-[1.3rem] flex flex-row text-start items-start space-x-2">
              <img
                className="inline-block mr-2 mt-1"
                src={LocationIcon}
                width={15}
                height={15}
                alt="A gray GPS pin"
              />
              {auctionData.websiteLocation}
            </p>
            <p className="flex justify-start">
              <a
                href={
                  auctionData.viewLotUrl.startsWith("https://ssllc.com/")
                    ? `../../${auctionData.viewLotUrl.replace(
                        "https://ssllc.com/",
                        ""
                      )}`
                    : auctionData.viewLotUrl
                }
                className="block w-52 xl:w-48 px-4 py-2 text-white hover:text-white rounded-[4px] transition duration-300 text-[1.2rem] font-normal shadow-md bg-red hover:bg-scarlet-red"
              >
                View Catalog
              </a>
            </p>
            <p className="flex justify-start">
              <AddToCalendarButton
                name={auctionData.title}
                options={[
                  "Apple",
                  "Google",
                  "Outlook.com",
                  "Microsoft365",
                  "Yahoo",
                ]}
                description={auctionData.blurb + "\n" + auctionData.registerUrl}
                location={auctionData.location}
                startDate={startDateMoment.format("YYYY-MM-DD")}
                endDate={endDateMoment.format("YYYY-MM-DD")}
                startTime={startDateMoment.format("HH:mm")}
                endTime={endDateMoment.format("HH:mm")}
                timeZone="America/New_York"
                hideBranding="true"
              />
            </p>
            <p className="flex justify-start">
              <a
                href={"#questions-section"}
                className="block w-52 xl:w-48 px-4 py-2 text-white hover:text-white rounded-[4px] transition duration-300 text-[1.2rem] font-normal shadow-md bg-royal-blue hover:bg-red"
              >
                Question?
              </a>
            </p>
          </div>
          <div className="w-full md:w-3/4 flex flex-col text-left">
            <p className="text-[1.5rem]">
              <span className="text-red font-semibold">Opens: </span>
              {startDateMoment.format("LLL")}
              <br />
              <span className="text-red font-semibold">Closes: </span>
              {endDateMoment.format("LLL")}
            </p>
            <h5 className="text-[1.3rem] mb-2.5 mt-2">
              {auctionData.shortDescriptionTitle}
            </h5>
            <p>{auctionData.blurb}</p>
          </div>
        </div>
        <div className="pt-10">
          <div className="w-full bg-container-gray rounded-[15px] min-h-[50vh]">
            {/* Tab Headers */}
            <div className="hidden md:flex w-full rounded-t-[15px]">
              {/* First Tab: Featured Equipment */}
              <button
                className={`w-1/2 flex items-center justify-center gap-2 px-6 py-5 font-semibold rounded-t-[15px] transition-colors duration-300 ${
                  activeTab === 0
                    ? "text-red border-b border-white"
                    : "bg-red text-white hover:text-red border-b border-charcoal hover:bg-light-gray"
                }`}
                onClick={() => setActiveTab(0)}
                onMouseEnter={() => setHoveredTab(0)} // Set hovered tab to 0
                onMouseLeave={() => setHoveredTab(null)} // Reset hovered tab
              >
                <FontAwesomeIcon
                  icon={faChevronCircleDown}
                  size="xl"
                  style={{
                    color:
                      activeTab === 0 || hoveredTab === 0
                        ? "#c42724"
                        : "transparent",
                  }}
                />
                <h4 className="mb-0">Featured Equipment</h4>
              </button>

              {/* Second Tab: Pickup & Payment Details */}
              {auctionData.pickupPaymentDetails && (
                <button
                  className={`w-1/2 flex items-center justify-center gap-2 px-6 py-5 font-semibold rounded-t-[15px] transition-colors duration-300 ${
                    activeTab === 1
                      ? "text-red border-b border-white"
                      : "bg-red text-white hover:text-red border-b border-charcoal hover:bg-light-gray"
                  }`}
                  onClick={() => setActiveTab(1)}
                  onMouseEnter={() => setHoveredTab(1)} // Set hovered tab to 1
                  onMouseLeave={() => setHoveredTab(null)} // Reset hovered tab
                >
                  <FontAwesomeIcon
                    icon={faChevronCircleDown}
                    size="xl"
                    style={{
                      color:
                        activeTab === 1 || hoveredTab === 1
                          ? "#c42724"
                          : "transparent",
                    }}
                  />
                  <h4 className="mb-0">Pickup & Payment Details</h4>
                </button>
              )}
            </div>

            {/* Tab Content */}
            <div className="hidden md:block p-10 rounded-b-[15px]">
              {activeTab === 0 && auctionData.description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${auctionData.description}`,
                  }}
                  className="[&_ul]:list-disc [&_ul]:pl-6 text-charcoal"
                />
              )}

              {activeTab === 1 && auctionData.pickupPaymentDetails && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${auctionData.pickupPaymentDetails}`,
                  }}
                  className="[&_ul]:list-disc [&_ul]:pl-6 text-charcoal"
                />
              )}
            </div>

            {/* Accordion View for Small Screens */}
            <div className="block md:hidden">
              {/* First Tab: Featured Equipment */}
              <div
                className={`rounded-t-[15px] flex flex-row items-center justify-center px-6 py-3 font-semibold transition-colors duration-300 ${
                  activeTab === 0
                    ? "text-red border-b border-red"
                    : "bg-red text-white hover:text-red border-b border-red hover:bg-light-gray"
                }`}
                onClick={() => setActiveTab(0)}
                onMouseEnter={() => setHoveredTab(0)}
                onMouseLeave={() => setHoveredTab(null)}
              >
                <FontAwesomeIcon
                  icon={faChevronCircleDown}
                  size="xl"
                  style={{
                    color:
                      activeTab === 0 || hoveredTab === 0
                        ? "#c42724"
                        : "transparent",
                  }}
                />
                <h4 className="mb-0 ml-2">Featured Equipment</h4>
              </div>
              {activeTab === 0 && auctionData.description && (
                <div
                  className="bg-light-gray p-4 rounded-b-[15px] text-charcoal [&_ul]:list-disc [&_ul]:pl-6"
                  dangerouslySetInnerHTML={{
                    __html: `${auctionData.description}`,
                  }}
                />
              )}

              {/* Second Tab: Pickup & Payment Details */}
              {auctionData.pickupPaymentDetails && (
                <>
                  <div
                    className={` flex flex-row items-center justify-center px-6 py-3 font-semibold transition-colors duration-300 mt-2 ${
                      activeTab === 1
                        ? "text-red border-b border-red"
                        : "bg-red text-white hover:text-red border-b border-red hover:bg-light-gray rounded-[15px]"
                    }`}
                    onClick={() => setActiveTab(1)}
                    onMouseEnter={() => setHoveredTab(1)}
                    onMouseLeave={() => setHoveredTab(null)}
                  >
                    <FontAwesomeIcon
                      icon={faChevronCircleDown}
                      size="xl"
                      style={{
                        color:
                          activeTab === 1 || hoveredTab === 1
                            ? "#c42724"
                            : "transparent",
                      }}
                    />
                    <h4 className="mb-0 ml-2">Pickup & Payment Details</h4>
                  </div>
                  {activeTab === 1 && (
                    <div
                      className="bg-light-gray p-4 rounded-b-[15px] text-charcoal [&_ul]:list-disc [&_ul]:pl-6"
                      dangerouslySetInnerHTML={{
                        __html: `${auctionData.pickupPaymentDetails}`,
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <FAQ
        title="Auctions FAQs"
        items={auctionsFaqItems}
        backgroundColor={colors.lightGray}
        backgroundImage={M_GRAY_BG}
        shadowColor="shadow-mediumGray-top"
      />
      <div id="questions-section">
        <ContactForm
          title="Got questions? We’re here to help!"
          body={
            <p>
              If our FAQs didn’t cover it, feel free to reach out. Click below
              to contact us directly – we’re happy to assist!
            </p>
          }
          backgroundColor={colors.containerGray}
          fontShadowColor={colors.red}
          shadow="shadow-red-bottom"
          formType="Auction Support"
        />
      </div>
    </Layout>
  )
}

export default AuctionDetail

export const query = graphql`
  query ($slug: String!) {
    auction(slug: { eq: $slug }) {
      title
      description
      pickupPaymentDetails
      faq
      startDate {
        seconds
      }
      endDate {
        seconds
      }
      detailImageUrl
      summaryImageUrl
      location
      websiteLocation
      blurb
      shortDescriptionTitle
      registerUrl
      viewLotUrl
      bidNowUrl
    }
  }
`
